import React, { useEffect, useState } from 'react'

function Confide() {
    const [mobile, SetImobile] = useState(window.innerWidth < 507)
    useEffect(() => {
        const hundleSize = () => {
            SetImobile(window.innerWidth < 507)
        }
        window.addEventListener('resize', hundleSize)
        return () => window.removeEventListener('resize', hundleSize)
    }, [])
    return (
        <div class="p-4">
            <div class="bg-white rounded-lg border p-4">
                <h1 class={` font-bold ${mobile ? 'text-[20px]' : 'text-[25px]'} text-blue-800 font-serif text-center w-full`}>Politique de confidentialité de Varia Trade Center</h1>
                <div class="mt-4">
                    <div class="mb-4">
                        <h2 class={` ${mobile ? 'text-[18px] text-center' : 'text-[22px]'}  font-semibold text-blue-600`}>Informations collectées</h2>
                        <p className='text-gray-600 leading-7 pl-5 font-normal'>
                            Types d'informations personnelles collectées (Votre Nom, Prénom,
                            Adresses e-mail, Numéro de Téléphone, Code agent, Photo de profil et etc.)
                            Informations sur l'appareil collectées (Information de connexion,
                            Autorisation d'accès au caméra et stockage, etc.)
                            Autres données collectées via l'application (précisez lesquelles)
                        </p>
                    </div>
                    <div class="mt-10">
                        <h2 class={` text-[25px] ${mobile ? 'text-[18px] text-center' : 'text-[22px]'}  font-semibold text-blue-600`}>Utilisation des données</h2>
                        <p className='mt-2 bg-slate-100 rounded-md '>
                            <p class={`font-semibold text-gray-500   ${mobile ? 'text-[16px] text-center w-full ' : 'text-[20px] pl-5 '}`}>Objectifs de l'utilisation des données collectées</p>
                            <div className='h-[1px] bg-slate-400 w-full mt-2'></div>
                            <ul className='py-2 text-gray-600  list-disc pl-7'>
                                <li className='mb-2 '>
                                    Les données  collectées sont utilisées dans le cadre des services
                                    d'échanges de monnaie électronique proposés par notre application</li>

                                <li className='mb-2 '>
                                    Nous collectons des informations personnelles telles que le Nom,
                                    Prénom, Adresses e-mail, Numéro de Téléphone, Code agent, Photo
                                    de profil et les informations de paiement nécessaires pour faciliter les
                                    transactions de monnaie électronique.</li>
                                <li className='mb-2 '>Les données collectées sont utilisées pour vérifier l'identité des
                                    utilisateurs et assurer la sécurité des transactions financières</li>
                                <li className='mb-2 '>Les informations sur l'appareil sont collectées pour optimiser
                                    l'expérience utilisateur et garantir la compatibilité avec les systèmes
                                    d'exploitation et les appareils pris en charge.</li>
                                <li className='mb-2 '>
                                    Les données collectées peuvent également être utilisées à des fins
                                    d'analyse afin d'améliorer nos services et de répondre aux besoins
                                    des utilisateurs.
                                </li>
                            </ul>
                        </p>
                        <p className='mt-2 bg-slate-100 rounded-md '>
                            <p class={`font-semibold text-gray-500   ${mobile ? 'text-[16px] text-center w-full ' : 'text-[20px] pl-5 '}`} >Partage de données</p>
                            <div className='h-[1px] bg-slate-400 w-full mt-2'></div>
                            <ul className='py-2 text-gray-600  list-disc pl-7'>
                                <li className='mb-2 '>Nous tenons à souligner que nous ne partageons pas les données
                                    personnelles collectées avec des tiers, sauf dans les cas expressément
                                    autorisés par les utilisateurs ou exigés par la loi.</li>
                                <li className='mb-2 '>Nous ne vendons, ne louons et ne divulguons pas les informations
                                    personnelles des utilisateurs à des tiers à des fins commerciales ou
                                    publicitaires.</li>
                                <li className='mb-2 '>Les données collectées sont strictement utilisées à des fins internes,
                                    et nous ne les partageons pas en dehors de notre organisation ou de
                                    notre application.</li>
                            </ul>
                        </p>
                        <p className='mt-2 bg-slate-100 rounded-md '>
                            <p class={`font-semibold text-gray-500   ${mobile ? 'text-[16px] text-center w-full ' : 'text-[20px] pl-5 '}`}>Choix des utilisateurs</p>
                            <div className='h-[1px] bg-slate-400 w-full mt-2'></div>
                            <ul className='py-2 text-gray-600  list-disc pl-7'>
                                <li className='mb-2 '>Nous comprenons l'importance de vous offrir un contrôle sur vos
                                    propres données personnelles. Par conséquent, vous avez la
                                    possibilité de prendre les mesures suivantes :</li>
                                <li className='mb-2 '>Vous pouvez vous désinscrire ou vous désabonner de certaines
                                    collectes de données en ajustant les paramètres de confidentialité de
                                    l'application.</li>
                                <li className='mb-2 '>Si vous souhaitez que certaines de vos données personnelles soient
                                    supprimées de nos systèmes, vous pouvez nous contacter en utilisant
                                    les coordonnées fournies dans cette politique de confidentialité.
                                    Nous traiterons votre demande dans les meilleurs délais et
                                    conformément aux lois applicables.</li>
                            </ul>
                        </p>
                        <p className='mt-2 bg-slate-100 rounded-md '>
                            <p class={`font-semibold text-gray-500   ${mobile ? 'text-[16px] text-center w-full ' : 'text-[20px] pl-5 '}`}>Mesures de sécurité</p>
                            <div className='h-[1px] bg-slate-400 w-full mt-2'></div>
                            <ul className='py-2 text-gray-600  list-disc pl-7'>
                                <li className='mb-2 '>Nous accordons une grande importance à la sécurité des données de
                                    nos utilisateurs. Nous avons mis en place des mesures de sécurité
                                    appropriées pour protéger vos informations personnelles contre tout
                                    accès non autorisé, toute divulgation ou toute utilisation abusive.</li>
                                <li className='mb-2 '>Vos données personnelles sont stockées dans notre système sécurisé,
                                    où elles sont protégées par des mesures techniques,
                                    organisationnelles et administratives appropriées.</li>
                                <li className='mb-2 '>Nous nous engageons à ne pas partager vos données personnelles
                                    avec des tiers, sauf si cela est expressément autorisé par vous ou si
                                    cela est requis par la loi.</li>
                                <li className='mb-2 '>Cependant, veuillez noter que malgré nos efforts pour assurer la
                                    sécurité des données, aucune méthode de transmission ou de
                                    stockage électronique n'est totalement sécurisée. Par conséquent,
                                    nous ne pouvons garantir une sécurité absolue des données.</li>
                            </ul>
                        </p>
                        <p className='mt-2 bg-slate-100 rounded-md '>
                            <p class={`font-semibold text-gray-500   ${mobile ? 'text-[16px] text-center w-full ' : 'text-[20px] pl-5 '}`}>Services tiers</p>
                            <div className='h-[1px] bg-slate-400 w-full mt-2'></div>
                            <ul className='py-2 text-gray-600  list-disc pl-7'>
                                <li className='mb-2 '>À l'heure actuelle, notre application n'intègre aucun service tiers.
                                    Toutes les données collectées sont utilisées exclusivement à des fins
                                    internes, conformément à notre politique de confidentialité.</li>
                                <li className='mb-2 '>Cependant, nous prévoyons d'intégrer des services tiers dans le futur
                                    pour améliorer certaines fonctionnalités de l'application. Lorsque cela
                                    se produira, nous mettrons à jour notre politique de confidentialité
                                    pour inclure des informations détaillées sur ces services tiers, y
                                    compris la manière dont ils traitent les données des utilisateurs.</li>
                                <li className='mb-2 '>Nous vous encourageons à consulter régulièrement notre politique
                                    de confidentialité pour être informé des mises à jour et des changements concernant les
                                    services tiers intégrés à notre
                                    application.</li>
                            </ul>
                        </p>
                        <p className='mt-2 bg-slate-100 rounded-md '>
                            <p class={`font-semibold text-gray-500   ${mobile ? 'text-[16px] text-center w-full ' : 'text-[20px] pl-5 '}`}>Mises à jour de la politique de confidentialité</p>
                            <div className='h-[1px] bg-slate-400 w-full mt-2'></div>
                            <ul className='text-gray-600 bg-slate-100 py-4 rounded-md list-disc pl-7'>
                                <li className='mb-2 '>Nous nous engageons à maintenir notre politique de confidentialité à
                                    jour pour refléter nos pratiques actuelles en matière de collecte,
                                    d'utilisation et de protection des données.</li>
                                <li className='mb-2 '>En cas de changements importants dans notre politique de
                                    confidentialité, nous vous notifierons en temps réel, directement via
                                    l'application ou par un autre moyen de communication approprié.</li>
                                <li className='mb-2 '>De plus, nous utiliserons l'adresse e-mail que vous avez fournie lors
                                    de votre inscription pour vous informer des mises à jour de la
                                    politique de confidentialité. Il est de votre responsabilité de vous
                                    assurer que votre adresse e-mail est à jour et que vous consultez
                                    régulièrement vos e-mails.</li>
                                <li className='mb-2 '>Nous pourrons également afficher un avis clair et visible dans
                                    l'application pour vous informer des modifications de la politique de
                                    confidentialité et vous permettre d'en prendre connaissance avant de
                                    continuer à utiliser nos services.</li>
                            </ul>
                        </p>
                    </div>
                    <p className='text-gray-500 mt-10 text-center'>
                        En conclusion, nos politiques de confidentialité visent à garantir la
                        protection et la sécurité des données personnelles de nos utilisateurs. Nous
                        collectons et utilisons les données de manière responsable, en respectant
                        les lois et réglementations applicables. Les données collectées sont utilisées
                        uniquement dans le cadre des services d'échanges de monnaie
                        électronique que nous proposons, et ne sont pas partagées avec des tiers,
                        sauf dans les cas autorisés par les utilisateurs ou exigés par la loi. Nous
                        offrons également aux utilisateurs la possibilité de se désinscrire ou de
                        demander la suppression de certaines données. Nous prenons des mesures
                        de sécurité appropriées pour protéger les données, bien que nous ne
                        puissions garantir une sécurité absolue. En cas de modifications
                        importantes de notre politique de confidentialité, nous informerons les
                        utilisateurs en temps réel, notamment par e-mail et via des notifications
                        dans l'application. Nous nous engageons à maintenir notre politique de
                        confidentialité à jour et à respecter la confidentialité des données de nos
                        utilisateurs.
                    </p>
                </div>
            </div>
        </div>


    )
}

export default Confide
